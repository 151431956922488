@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,700&display=swap');

@font-face {
    font-family: Libre_Baskerville;
    src: url(../fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf);
    font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: 0.5s ease-in;
}


p{
    font-family: 'Ubuntu', sans-serif;
}


body {
  font-family: 'Ubuntu', sans-serif;
  background-color:#ffffff !important;
}


span {

    color: #eb278f !important;
}

.text-primary {

    color: #01aff0 !important;
}


.home {

    background-image: url('../img/bg.png');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: #fff !important;
}


.home h1 {

    font-family: Libre_Baskerville, serif !important;
}


.home span {

    color: #01aff0 !important;
}

.btn-dark {

    background-color: #01aff0 !important;
    color: #fff !important;
    border: none !important;
}

.btn-dark:hover {

    border: 1px solid #01aff0 !important;
    background-color: transparent !important;
    color: #fff !important;
}


.home-text-space {

    letter-spacing: 1.5rem !important;
}


.banner .card{

    border: none !important;
    border-radius: 1rem !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.banner h1{

    color: #01aff0 !important;
}

.banner span {

    color: #eb278f !important;
}

.ship .card{

    border: none !important;
    border-radius: 1rem !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.started .card {

    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 2rem !important;
    background: #eb278f !important;
    color: #fff !important;
  }
  
  .started button {
  
    width: 50% !important;
  }

.bg-pink {
    
    background-color: #eb278f !important;
    color: #fff !important;
}

footer {

    background-color: #000 !important;
    color: #fff !important;
}